<template>
  <div id="app">
     <div class='sidebar'>
      <div class='heading'><img src="https://id1o3gz7dylz.compat.objectstorage.us-ashburn-1.oraclecloud.com/brime/brime_logo.svg" width="100px" style="margin-top: 20px; margin-bottom: -30px;">
        <span style="font-size: 24px; display: inline-block; vertical-align: middle; line-height: normal;">Global Network</span>
        <h4 style="margin-top: 50px; margin-bottom: 20px; color: #7F7C82">Brime Speedtest:<br> <a href="https://speed.brimelive.com/" target="_blank" style="color: white ">https://speed.brimelive.com</a></h4>
      </div>
    <div id='listings' class='listings'></div>
    </div>
    <div id='map' class='map'> </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'App',
  components: {
  },
  mounted() {
  // This will let you use the .remove() function later on
  if (!('remove' in Element.prototype)) {
    Element.prototype.remove = function() {
      if (this.parentNode) {
          this.parentNode.removeChild(this);
      }
    };
  }

  mapboxgl.accessToken = 'pk.eyJ1IjoiYnJpbWUiLCJhIjoiY2tybWN5M2NnMWN4ejJvcDhpeDcxYnl1YiJ9.c-0zaULwLsdG3dZuAXKCPw';

  // This adds the map
  var map = new mapboxgl.Map({
    // container id specified in the HTML
    container: 'map',
    // style URL
    style: 'mapbox://styles/mapbox/dark-v9',
    // initial position in [long, lat] format
    center: [12.547163, 39.0300218],
    // initial zoom
    zoom: 1.3
  });

  var stores = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            -77.547163,
            39.0300218
          ]
        },
        "properties": {
          "location": "ASHBURN, VA - USA",
          "hostname": "rtmp://ingest-us-ashburn.brime.tv/live",
          "oracle": true
        }
      },
      // {
      //   "type": "Feature",
      //   "geometry": {
      //     "type": "Point",
      //     "coordinates": [
      //       -87.629799,
      //       41.878113
      //     ]
      //   },
      //   "properties": {
      //     "location": "CHICAGO, IL - USA",
      //     "hostname": "rtmp://ingest-us-chicago.brime.tv/live",
      //   }
      // },
      // {
      //   "type": "Feature",
      //   "geometry": {
      //     "type": "Point",
      //     "coordinates": [
      //       -122.332069,
      //       47.606209
      //     ]
      //   },
      //   "properties": {
      //     "location": "SEATTLE, WA - USA",
      //     "hostname": "rtmp://ingest-us-seattle.brime.tv/live",
      //   }
      // },
      // {
      //   "type": "Feature",
      //   "geometry": {
      //     "type": "Point",
      //     "coordinates": [
      //       -84.387985,
      //       33.748997
      //     ]
      //   },
      //   "properties": {
      //     "location": "ATLANTA, GA - USA",
      //     "hostname": "rtmp://ingest-us-atlanta.brime.tv/live",
      //   }
      // },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            -121.957496,
            37.2970523
          ]
        },
        "properties": {
          "location": "SAN JOSE, CA - USA",
          "hostname": "rtmp://ingest-us-sanjose.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            -112.4052331,
            33.6056711
          ]
        },
        "properties": {
          "location": "PHOENIX, AZ - USA",
          "hostname": "rtmp://ingest-us-phoenix.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            -73.567253,
            45.501690
          ]
        },
        "properties": {
          "location": "MONTREAL, CANADA",
          "hostname": "rtmp://ingest-ca-montreal.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            -46.633308,
            -23.550520
          ]
        },
        "properties": {
          "location": "SÃO PAULO, BRAZIL",
          "hostname": "rtmp://ingest-sa-saopaulo.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            27.8999389,
            -26.1714537
          ]
        },
        "properties": {
          "location": "JOHANNESBURG, SOUTH AFRICA",
          "hostname": "rtmp://ingest-sa-johannesburg.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            37.3273304,
            55.4792046
          ]
        },
        "properties": {
          "location": "MOSCOW, RUSSIA",
          "hostname": "rtmp://ingest-ru-moscow.brime.tv/live"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            8.5665245,
            50.121301
          ]
        },
        "properties": {
          "location": "FRANKFURT, GERMANY",
          "hostname": "rtmp://ingest-eu-frankurt.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            4.8339212,
            52.3547498
          ]
        },
        "properties": {
          "location": "AMSTERDAM, NETHERLANDS",
          "hostname": "rtmp://ingest-eu-amsterdam.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            8.5016957,
            47.3775078
          ]
        },
        "properties": {
          "location": "ZURICH, SWITZERLAND",
          "hostname": "rtmp://ingest-eu-zurich.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            -0.2416799,
            51.5287718
          ]
        },
        "properties": {
          "location": "LONDON, UNITED KINGDOM",
          "hostname": "rtmp://ingest-eu-london.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            55.2924914,
            25.2653471
          ]
        },
        "properties": {
          "location": "DUBAI, UNITED ARAB EMIRATES",
          "hostname": "rtmp://ingest-uae-dubai.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            139.2093962,
            35.5090627
          ]
        },
        "properties": {
          "location": "TOKYO, JAPAN",
          "hostname": "rtmp://ingest-apac-tokyo.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            126.8340037,
            37.5640455
          ]
        },
        "properties": {
          "location": "SEOUL, SOUTH KOREA",
          "hostname": "rtmp://ingest-apac-seoul.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.8773928,
            19.0259899
          ]
        },
        "properties": {
          "location": "MUMBAI, INDIA",
          "hostname": "rtmp://ingest-apac-mumbai.brime.tv/live",
          "oracle": true
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            150.7918928,
            -33.8478796
          ]
        },
        "properties": {
          "location": "SYDNEY, AUSTRALIA",
          "hostname": "rtmp://ingest-apac-sydney.brime.tv/live",
          "oracle": true
        }
      }
      ]
    };
  // This adds the data to the map
  map.on('load', function (e) {
    // This is where your '.addLayer()' used to be, instead add only the source without styling a layer
    map.addSource("places", {
      "type": "geojson",
      "data": stores
    });
    map.addControl(
    new mapboxgl.GeolocateControl({positionOptions: {enableHighAccuracy: true}, trackUserLocation: true}));
    // Initialize the list
    buildLocationList(stores);

    var geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
    });

    map.addControl(geocoder, 'top-left');

    map.addSource('single-point', {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": [] // Notice that initially there are no features
      }
    });

    map.addLayer({
      "id": "point",
      "source": "single-point",
      "type": "circle",
      "paint": {
        "circle-radius": 10,
        "circle-color": "#007cbf",
        "circle-stroke-width": 3,
        "circle-stroke-color": "#fff"
      }
    });

    geocoder.on('result', function(ev) {
      var searchResult = ev.result.geometry;
      map.getSource('single-point').setData(searchResult);

      var units = "miles";
      stores.features.forEach(function(store){
        Object.defineProperty(store.properties, 'distance', {
          value: turf.distance(searchResult, store.geometry, units),
          writable: true,
          enumerable: true,
          configurable: true
        });
      });

      stores.features.sort(function(a,b){
        if (a.properties.distance > b.properties.distance) {
          return 1;
        }
        if (a.properties.distance < b.properties.distance) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      var listings = document.getElementById('listings');
      while (listings.firstChild) {
        listings.removeChild(listings.firstChild);
      }

      buildLocationList(stores);

      function sortLonLat(storeIdentifier) {
        var lats = [stores.features[storeIdentifier].geometry.coordinates[1], searchResult.coordinates[1]]
        var lons = [stores.features[storeIdentifier].geometry.coordinates[0], searchResult.coordinates[0]]

        var sortedLons = lons.sort(function(a,b){
            if (a > b) { return 1; }
            if (a.distance < b.distance) { return -1; }
            return 0;
          });
        var sortedLats = lats.sort(function(a,b){
            if (a > b) { return 1; }
            if (a.distance < b.distance) { return -1; }
            return 0;
          });

        map.fitBounds([
          [sortedLons[0], sortedLats[0]],
          [sortedLons[1], sortedLats[1]]
        ], {
          padding: 100
        });
      };

      sortLonLat(0);
      createPopUp(stores.features[0]);

    });
  });

  // This is where your interactions with the symbol layer used to be
  // Now you have interactions with DOM markers instead
  stores.features.forEach(function(marker, i) {
    // Create an img element for the marker
    var el = document.createElement('div');
    el.id = "marker-" + i;
    el.className = 'marker';
    // Add markers to the map at all points
    new mapboxgl.Marker(el, {offset: [0, -23]})
        .setLngLat(marker.geometry.coordinates)
        .addTo(map);

    el.addEventListener('click', function(e){
        // 1. Fly to the point
        flyToStore(marker);

        // 2. Close all other popups and display popup for clicked store
        createPopUp(marker);

        // 3. Highlight listing in sidebar (and remove highlight for all other listings)
        var activeItem = document.getElementsByClassName('active');

        e.stopPropagation();
        if (activeItem[0]) {
           activeItem[0].classList.remove('active');
        }

        var listing = document.getElementById('listing-' + i);
        listing.classList.add('active');

    });
  });


  function flyToStore(currentFeature) {
    map.flyTo({
        center: currentFeature.geometry.coordinates,
        zoom: 8
      });
  }

  function createPopUp(currentFeature) {
    var popUps = document.getElementsByClassName('mapboxgl-popup');
    if (popUps[0]) popUps[0].remove();
    if (currentFeature.properties.oracle){
      var popup = new mapboxgl.Popup({closeOnClick: false})
          .setLngLat(currentFeature.geometry.coordinates)
    
          .setHTML('<h3>Edge Ingest</h3>' +
            '<center><h4 style="font-weight: bold;">' + currentFeature.properties.location + '</h4></center><a href="https://www.oracle.com/cloud/compute/" target="_blank"><img src="https://cdn.worldvectorlogo.com/logos/oracle-logo.svg" width="100%" style="display: block;margin-left: auto; margin-right: auto; margin-bottom: 10px; width: 60%;"></a>')
          .addTo(map);
    } else {
      var popup = new mapboxgl.Popup({closeOnClick: false})
          .setLngLat(currentFeature.geometry.coordinates)
    
          .setHTML('<center><h3>Edge Ingest</h3></center>' +
            '<center><h4 style="font-weight: bold;">' + currentFeature.properties.location + '</h4></center>')
          .addTo(map);
      
    }
  }


  function buildLocationList(data) {
    for (var i = 0; i < data.features.length; i++) {
      var currentFeature = data.features[i];
      var prop = currentFeature.properties;

      var listings = document.getElementById('listings');
      var listing = listings.appendChild(document.createElement('div'));
      listing.className = 'item';
      listing.id = "listing-" + i;

      var link = listing.appendChild(document.createElement('a'));
      link.href = '#';
      link.className = 'title';
      link.dataPosition = i;
      link.innerHTML = prop.location;

      var details = listing.appendChild(document.createElement('div'));
      details.innerHTML = '<strong>'+prop.hostname+'</strong>';
      
      if (prop.distance) {
        var roundedDistance = Math.round(prop.distance*100)/100;
        details.innerHTML += '<p><strong>' + roundedDistance + ' miles away</strong></p>';
      }


      link.addEventListener('click', function(e){
        // Update the currentFeature to the store associated with the clicked link
        var clickedListing = data.features[this.dataPosition];

        // 1. Fly to the point
        flyToStore(clickedListing);

        // 2. Close all other popups and display popup for clicked store
        createPopUp(clickedListing);

        // 3. Highlight listing in sidebar (and remove highlight for all other listings)
        var activeItem = document.getElementsByClassName('active');

        if (activeItem[0]) {
           activeItem[0].classList.remove('active');
        }
        this.parentNode.classList.add('active');

      });
    }
  }
  }
}
</script>

<style>
body {
          color: #7F7C82;
          font:400 15px/22px 'Roboto', sans-serif;
          margin:0;
          padding:0;
          -webkit-font-smoothing:antialiased;
        }

        * {
          -webkit-box-sizing:border-box;
          -moz-box-sizing:border-box;
          box-sizing:border-box;
        }

        .sidebar {
          background: #151515;
          position:absolute;
          width:33.3333%;
          height:100%;
          top:0;left:0;
          overflow:hidden;
          border-right:1px solid rgba(0,0,0,0.25);
        }
        .pad2 {
          padding:20px;
        }

        .map {
          position:absolute;
          left:33.3333%;
          width:66.6666%;
          top:0;bottom:0;
        }

        h1 {
          font-size:22px;
          margin:0;
          font-weight:400;
          line-height: 20px;
          padding: 20px 2px;
        }

        a {
          color:#404040;
          text-decoration:none;
        }

        a:hover {
          color:#101010;
        }

        .heading {
          border-bottom:1px solid #eee;
          min-height:60px;
          padding:0 10px;
          background: #151515;
          color: #fff;
        }

        .listings {
          height: 85%;
          overflow: scroll;
          padding-bottom:60px;
        }

        .listings .item {
          display:block;
          border-bottom:1px solid #eee;
          padding:10px;
          text-decoration:none;
        }

        .listings .item:last-child { border-bottom:none; }
        .listings .item .title {
          display:block;
          color:#6F58C9;
          font-weight:700;
        }

        .listings .item .title small { font-weight:400; }
        .listings .item.active .title,
        .listings .item .title:hover { color:#FC3537; }
        .listings .item.active {
          background-color:#f8f8f8;
        }
        ::-webkit-scrollbar {
          width:3px;
          height:3px;
          border-left:0;
          background:rgba(0,0,0,0.1);
        }
        ::-webkit-scrollbar-track {
          background:none;
        }
        ::-webkit-scrollbar-thumb {
          background: linear-gradient(90deg, rgba(220,63,251,1) 0%, rgba(252,70,107,1) 100%);
          border-radius:0;
        }

        .marker {
          border: none;
          cursor: pointer;
          height: 15px;
          width: 15px;
          background: linear-gradient(90deg, rgba(220,63,251,1) 0%, rgba(252,70,107,1) 100%);
  border-radius: 50%;
        }

        .clearfix { display:block; }
        .clearfix:after {
          content:'.';
          display:block;
          height:0;
          clear:both;
          visibility:hidden;
        }

        /* Marker tweaks */
        .mapboxgl-popup {
          padding-bottom: 50px;
        }

        .mapboxgl-popup-close-button {
          display:none;
        }
        .mapboxgl-popup-content {
          font:400 15px/22px 'Roboto', sans-serif;
          padding:0;
          width:200px;
        }
        .mapboxgl-popup-content-wrapper {
          padding:1%;
        }
        .mapboxgl-popup-content h3 {
          background: linear-gradient(45deg, rgba(132,57,175,1) 0%, rgba(252,53,55,1) 100%);
          color:#fff;
          margin:0;
          display:block;
          padding:10px;
          border-radius:3px 3px 0 0;
          font-weight:700;
          margin-top:-15px;
        }

        .mapboxgl-popup-content h4 {
          margin:0;
          display:block;
          padding: 10px 10px 10px 10px;
          font-weight:400;
        }

        .mapboxgl-popup-content div {
          padding:10px;
        }

        .mapboxgl-container .leaflet-marker-icon {
          cursor:pointer;
        }

        .mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
          margin-top: 15px;
        }

        .mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
          border-bottom-color: #91c949;
        }

        .mapboxgl-ctrl-geocoder {
          border: 2px solid #00853e;
          border-radius: 0;
          position: relative;
          top: 0;
          width: 800px;
          margin-top: 0;
          border: 0;
        }

        .mapboxgl-ctrl-geocoder > div {
          min-width:100%;
          margin-left:0;
        }
</style>
